









import { Component, Prop, Vue } from "vue-property-decorator";
import { dashboardCreationDto } from "@/shared/dtos/dashboardCreationDto";
@Component({
  components: {
    BasicGaugeConfigurable: () =>
      import("@/components/Charts/BasicGauges/BasicGaugeConfigurable.vue"),
  },
})
export default class GaugesChartAyuntamiento extends Vue {
  @Prop() chart_prop!: dashboardCreationDto[];
  public mounted() {
    console.log(this.chart_prop, "GaugesChartAyuntamiento");
  }
}
